.App {
  font-size: 14pt;
  font-family: "Overpass Mono", monospace;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #101010;
  color: white;
  min-height: 100vh;
}

button {
  font-family: "Overpass Mono", monospace;
}

.header {
  background-color: #202020;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  color: white;
  padding: 20px;
  align-self: stretch;
}

@media (max-width: 540px) {
  .header {
    flex-direction: column;
  }
}

.leaflet-container {
  height: 90vh;
}

.link.logo {
  text-decoration: none;
  color: lime;
}

.link {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 18px;
  font-family: "Overpass Mono", monospace;
}

.header__link {
  margin: 0 20px;
  text-decoration: none;
}

@media (max-width: 540px) {
  .header__link {
    margin: 5px;
    text-decoration: underline;
  }
}

.home-page {
  margin: 0 30px;
}

.para--featured {
  color: cyan;
}

.para--warn {
  color: red;
}

.alpha-banner-container {
  overflow: hidden;
  position: relative;
  height: 180px;
  width: 180px;
}

.alpha-banner {
  color: white;
  background-color: red;
  width: 250px;
  line-height: 2;
  text-align: center;
  font-size: 22pt;
  position: fixed;
  top: 40px;
  right: -60px;
  transform: rotateZ(45deg);
  transition: opacity 1s, right 1s;
  opacity: 1;
}

@media (max-width: 720px) {
  .alpha-banner {
    opacity: 0.2;
    right: -300px;
  }
}

.link--featured {
  color: yellow;
}

.link--featured-2 {
  color: cyan;
}

.login-page, .register-page {
  margin: 80px 0;
  display: flex;
  flex-direction: column;
  width: 420px;
}

.form {
  display: flex;
  flex-direction: column;
}

.form__input {
  margin: 10px 0;
  width: 100%;
}

.form__input--invalid {
  border: 1px solid red;
}

input {
  font-family: "Overpass Mono", monospace;
  padding: 10px;
  border: 1px solid lime;
  background-color: black;
  color: lime;
}

 /* E F B */

.efb-container {
  font-size: 14pt;
  height: calc(100vh - 30px);
  overflow: hidden;
  width: calc(100% - 30px);
  padding: 15px;
  background-color: black;
  display: flex;
  flex-direction: column;
}

.efb-link {
  text-decoration: none;
}

.efb-heading {
  color: lime;
  text-align: center;
  width: 100%;
  margin: 0;
}

.column-2 {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.container__row {
  display: flex;
  flex-direction: row;
  width: calc(100% - 20px);
  margin: 10px;
}

.element {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  height: 80px;
  align-content: center;
}

.element__heading {
  color: white;
  font-size: 13pt;
}

.element__option {
  color: white;
}

.element__option--abort {
  color: lightcoral;
}

.element__data {
  color: cyan;
}

.element--right > * {
  text-align: right;
}

.refresh-button {
  border: none;
  background: none;
  color: lime;
  justify-content: center;
  align-items: end;
  display: flex;
  gap: 5px;
  padding: 5px;
}

.refresh-button svg {
  fill: lime;
}

.table {

}

.table thead {
  color: lime;
  text-decoration: underline;
}

.table td {
  padding: 5px 20px;
}